<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="4" md="3">
        <v-sheet rounded="lg" class="surface">
          <h1 class="text-center">Groups</h1>
          <v-divider class="my-2"/>
          <v-list color="transparent">
            <v-list-item-group v-model="activeGroup">
              <v-list-item
                  v-for="(n) in groupMembership"
                  :key="n._id" @change="changeGroup(n)" @input="changeGroup(n)"
              >
                <v-list-item-icon>
                  <v-icon>mdi-domain</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ getGroupName(n._id,'firstUse') }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-sheet>
      </v-col>
      <v-col cols="12" sm="8" md="9">
        <v-sheet
            min-height="70vh"
            rounded="lg"
            class="surface"
        >
          <v-sheet rounded="lg" class="background pt-2 my-3">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Announcements
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-layout>
                  <div>
                    <v-dialog
                        v-model="dialog"
                        width="500"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="secondary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                        >
                          <v-icon left>mdi-filter</v-icon>
                          Filter
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title class="text-h5 grey lighten-2">
                          Announcement filters
                        </v-card-title>
                        <v-card-text>
                          Use filter to help find posts of interest.
                        </v-card-text>
                        <v-card-text>
                          <div class="text-body-1">
                            <v-select
                                v-model="selectedPriorityFilter"
                                :items="priorityLevels"
                                :label="`Display Announcements of the following type${selectedPriorityFilter.length >1 ? '(s)':''}`"
                                :menu-props="{ top: false, offsetY: true }"
                                chips
                                multiple
                                outlined
                            >
                              <template v-slot:prepend-item>
                                <v-list-item
                                    ripple
                                    @click="toggle"
                                >
                                  <v-list-item-action>
                                    <v-icon :color="selectedPriorityFilter.length > 0 ? 'indigo darken-4' : ''">
                                      {{ messageFilterIcon }}
                                    </v-icon>
                                  </v-list-item-action>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      Select All
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-divider class="mt-2"></v-divider>
                              </template>
                            </v-select>
                          </div>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                              color="secondary"
                              @click="clearFilters"
                          >
                            Reset
                          </v-btn>
                          <v-btn
                              color="primary"
                              @click="dialog = false"
                          >
                            Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </div>
                </v-layout>
              </v-list-item-action>
            </v-list-item>
            <v-divider class="my-2"/>
            <v-btn block text disabled color="primary">
              <v-icon left>mdi-view-list</v-icon>
              List view
            </v-btn>
            <v-divider/>
          </v-sheet>
          <announcement-definitions :priority-list="priorityList"/>
          <announcement-status-definitions/>
          <post-modal :active-group='{}' :author-name="loggedInUser.name" :author-groups="authorGroups" :groups="groups"
                      :is-announcement-manager="isAnnouncementManager"
                      v-on:refreshPosts="refreshPosts"
                      v-on:newPost="newPost"/>

          <div v-if="loading && scopedPosts.length===0">
            <v-sheet rounded="lg" :class="`my-6 background`" v-for="n in 6" :key="n">
              <v-skeleton-loader
                  class="ml-0 pa-4  my-0 text-justify-end"
                  type="article, divider, actions "
              >
              </v-skeleton-loader>
            </v-sheet>
          </div>
          <v-sheet rounded="lg" v-if="!scopedPosts || scopedPosts.length===0 && !loading"
                   class="my-2 caption">
            No posts match your filter.
          </v-sheet>
          <post :item="post" v-for="(post,index) in scopedPosts"
                :active-group="activeGroup"
                :groups="groups"
                :user-id="loggedInUser.userId" :user-name="loggedInUser.name"
                :is-announcement-manager="isAnnouncementManager"
                :key="index"/>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PostModal from "@/components/modals/PostModal";
import {mapActions, mapState} from "vuex";
import AnnouncementDefinitions from "@/components/shared/AnnouncementDefinitions";
import AnnouncementStatusDefinitions from "@/components/shared/AnnouncementStatusDefinitions";
import Post from "@/components/shared/Post";
import {getGroupName} from "@/util/helpers";

export default {
  name: "AnnouncementsPage",
  components: {AnnouncementStatusDefinitions, AnnouncementDefinitions, PostModal, Post},

  data() {
    return {
      dialog: false,
      priorityList: [1, 2, 3],
      filterOpened: false,
      filterSigned: false,
      selectedPriorityFilter: [1, 2, 3],
      activeGroup:null,
      selectedGroups: []
    }
  },
  computed: {
    ...mapState("announcement", ['announcements']),
    ...mapState("app", ["loggedInUser", "loading"]),
    ...mapState("priorityLevels", ["priorityLevels"]),
    ...mapState("user", ["accountRoles","groupMembership"]),
    ...mapState("post",["posts"]),
    ...mapState("group",["groups"]),


    authorGroups(){
      if (!this.groupMembership) return []
      return this.groupMembership
    },

    allMessagePriorityLevelsSelected() {
      return this.selectedPriorityFilter.length === this.priorityList.length
    },
    someMessagePriorityLevelsSelected() {
      return this.selectedPriorityFilter.length > 0 && !this.allMessagePriorityLevelsSelected
    },

    messageFilterIcon() {
      if (this.allMessagePriorityLevelsSelected) return 'mdi-close-box'
      if (this.someMessagePriorityLevelsSelected) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },

    scopedPosts() {
      if (!this.posts) return []

      if (this.posts) {
        if(!this.activeGroup) {
          return this.posts.filter(a => this.selectedPriorityFilter.includes(a.priority) && a.isPublished)
        }
        return this.posts.filter(a => this.selectedPriorityFilter.includes(a.priority) && a.isPublished && a.groupId === this.activeGroup)
      }
      return []
    },

    isAnnouncementManager() {
      return this.accountRoles.findIndex(r => r.name === 'manageAnnouncements')>-1 || this.accountRoles.findIndex(r => r.name === 'rootAdmin') >-1
    }
  },
  methods: {
    ...mapActions("app", ["setLoading"]),
    ...mapActions("person", ["setPersons"]),
    ...mapActions("user", ["setFlyingStatus"]),
    ...mapActions("post",["setPosts","setActivePost", "setManagePostDialog"]),
    getGroupName(id,style){
      return getGroupName(this.groups,id,style)
    },

    async changeGroup(group) {
      this.activeGroup = group._id
      await this.setPosts()
    },

    async newPost() {
      await this.setActivePost({
        isNewPost: true,
        authorId: this.loggedInUser._id,
        authorName: this.loggedInUser.name,
        detail: null,
        priority: 3,
        dateFrom: new Date(),
        groupId: this.groupMembership[0]._id,
        groupName: this.groupMembership[0].name,
        dateTo: new Date(new Date().setMonth(new Date().getMonth() + 3))
      })
      this.setManagePostDialog(true)
    },

    clearFilters() {
      this.selectedPriorityFilter = [1, 2, 3]
      this.dialog = false
    },
    toggle() {
      this.$nextTick(() => {
        if (this.allMessagePriorityLevelsSelected) {
          this.selectedPriorityFilter = []
        } else {
          this.selectedPriorityFilter = this.priorityList.slice()
        }
      })
    },
    async refreshPosts() {
      await this.setLoading(true)
      await this.setPosts()
      await this.setFlyingStatus(this.loggedInUser._id)
      await this.setLoading(false)
    },
  },
  async mounted() {
    await this.setLoading(true)
    await this.setPosts()
    if(this.groupMembership.length == 1) {
      this.activeGroup = this.groupMembership[0]._id
    }
    await this.setLoading(false)
  }
}
</script>

<style scoped>

</style>